import Compra from '../components/Compra'
import React, { useState } from 'react'
import Layout from '../components/Layout'

import Chat from '../components/Compra/chat'

const CompraGuiada = () => {
  const [empezar, setEmpezar] = useState(false)
  return (
    <>
      {empezar ? (
        <Chat setEmpezar={setEmpezar} />
      ) : (
        <Compra setEmpezar={setEmpezar} />
      )}
    </>
  )
}

export default CompraGuiada
