import React from 'react'
import { StaticImage as Image } from 'gatsby-plugin-image'

const Compra = ({ setEmpezar }) => {
  return (
    <div className="h-[100vh] flex flex-col ">
      <div className="px-8 py-3 flex justify-center sticky bg-primary top-0  h-[5rem] z-50">
        <Image src="../../images/logo.png" alt="logo" />
      </div>
      <h1 className="text-2xl font-extrabold text-primary text-center mt-24">
        COTIZACIÓN <span className="text-secondary">GUIADA</span>
      </h1>

      <p className="text-base px-5 mt-5 text-center font-bold text-terciary">
        Te ayudamos a encontar tu empaque con nuestra búsqueda guiada
      </p>
      <div className="flex justify-between items-center mx-14 my-20 md:my-12 md:justify-center md:gap-20">
        <div className="w-[200px] md:w-[300px] lg:w-[400px]">
          <Image src="../../images/hombre.png" alt="avatar" />
          <p className="text-secondary text-center  text-xl font-black">
            Epack <span>BOT</span>
          </p>
        </div>

        <div className="flex flex-col items-end">
          <div className="bg-highlight w-36 md:w-48 lg:w-72 px-3 py-1   flex flex-col  text-right rounded-md">
            <p className="text-terciary text-sm md:text-xl lg:text-3xl">
              ¿Que tipo de <span>producto buscas?</span>
            </p>
          </div>
          <div className="bg-highlight w-12  px-4 py-3  mt-2 flex gap-1 justify-center items-center text-right rounded-md">
            <span className="bg-terciary p-[3px] rounded-full"></span>
            <span className="bg-terciary p-[3px] rounded-full"></span>
            <span className="bg-terciary p-[3px] rounded-full"></span>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          className="bg-primary px-6 py-2 mx-20   text-center rounded-md mb-20"
          onClick={() => setEmpezar(true)}
        >
          <p className="text-yellow text-xl">EMPEZAR AHORA</p>
        </button>
      </div>
    </div>
  )
}
export default Compra
