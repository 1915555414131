import React, { useEffect, useState } from 'react'
import { StaticImage as Image } from 'gatsby-plugin-image'

const Mensaje = ({
  texto,
  w = 'w-full',
  position = '',
  bgColor = 'highlight',
  color = 'text-black',
  max = ''
}) => {
  const [hora, setHora] = useState()
  const hoy = new Date()
  const horas = hoy.getHours() + ':' + hoy.getMinutes()

  useEffect(() => {
    setHora(horas)
  }, [])

  return (
    <div
      className={`${
        bgColor === 'yellow' && 'flex-row-reverse'
      } flex items-center gap-5`}
    >
      <div className="w-16 h-16 rounded-full overflow-hidden bg-slate-500 -mt-3 hidden lg:block">
        <Image src="../../images/hombre.png" />
      </div>
      <div
        className={` flex ${
          position === '' ? 'justify-start' : 'justify-end'
        }  relative w-full lg:w-auto`}
      >
        <span
          className={`block border-[7px] ${
            bgColor === 'white'
              ? 'border-r-white border-r-[12px] left-[-19px]'
              : bgColor === 'yellow'
              ? 'border-l-yellow border-l-[12px] right-[-19px]'
              : 'border-r-highlight border-r-[12px] left-[-19px]'
          }   border-transparent   absolute mensaje `}
        ></span>
        <div
          className={`${w === 'inline-block' ? 'inline-block' : 'w-full'} ${
            max === '' ? '' : 'max-w-[15rem]'
          } py-2  md:w-auto md:max-w-[600px]`}
        >
          <div
            className={`${
              bgColor === 'white'
                ? 'bg-white'
                : bgColor === 'yellow'
                ? 'bg-yellow'
                : 'bg-highlight'
            } py-3 px-4 xl:py-4`}
          >
            <p
              className={`${
                color === 'text-black' ? 'text-black' : 'text-terciary'
              }  text-[15px]`}
            >
              {texto}
            </p>
          </div>
          <p
            className={`${
              bgColor === 'yellow' ? 'text-left' : 'text-right'
            } text-xs text-[8px] text-terciary  md:text-[12px] `}
          >
            Hora actual: {hora}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Mensaje
