import React from 'react'

const Button = ({ onClick, className, value, disabled }) => {
  return (
    <button
      className={`${className} ${
        disabled && 'opacity-60'
      } flex-1 rounded-md py-1 md:py-2 text-white font-semibold mb-3`}
      onClick={onClick}
      disabled={disabled}
    >
      {value}
    </button>
  )
}

export default Button
