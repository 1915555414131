import React, { useState } from 'react'
import { StaticImage as Image } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'
import Table from './table'
import Bolsas from './bolsas'
import Mensaje from './mensaje'
import Preguntas from './preguntas'
import Guardar from './guardar'
import Bolsa1 from '../../images/bolsa1.png'
import Bolsa2 from '../../images/bolsa2.png'
import Bolsa3 from '../../images/bolsa3.png'
import Bolsa4 from '../../images/bolsa4.png'
import Bolsa5 from '../../images/bolsa5.png'
import Bolsa6 from '../../images/bolsa6.png'
import Respuestas from './respuestas'
import { dataTableM } from './dataTable'

const Chat = ({ setEmpezar }) => {
  const [stateChat, setStateChat] = useState({
    nombre: '',
    mensaje: false,
    bolsas: false,
    finalizar: '',
    checked: false,
    tipoCaja: '',
    numero: 0,
    bolsaMedida: {},
    caja: '',
    seleccionarCaja: true
  })
  const {
    nombre,
    mensaje,
    bolsas,
    finalizar,
    checked,
    tipoCaja,
    numero,
    bolsaMedida,
    caja,
    seleccionarCaja
  } = stateChat

  const [state, setState] = useState({
    cantidad: 0,
    impresion: '',
    lados: '',
    color: '5C5A5A',
    logo: '',
    adjuntar: '',
    file: '',
    delivery: '',
    direccion: '',
    guardar: false
  })

  const bolsaImg =
    numero === 1
      ? Bolsa1
      : numero === 2
      ? Bolsa2
      : numero === 3
      ? Bolsa3
      : numero === 4
      ? Bolsa4
      : numero === 5
      ? Bolsa5
      : Bolsa6

  const dataTable = dataTableM.map((data) => ({ ...data, imagen: bolsaImg }))

  const onChange = (e) => {
    setStateChat({ ...stateChat, nombre: e.target.value })
  }
  const onClick = () => {
    setStateChat({ ...stateChat, mensaje: true })
  }

  const onClickBolsas = (valor, numero, caja) => {
    setStateChat({
      ...stateChat,
      bolsas: true,
      tipoCaja: valor,
      numero: numero,
      caja: caja,
      seleccionarCaja: false
    })
  }

  return (
    <motion.div
      className={bolsas ? '' : 'h-[93vh] flex flex-col '}
      initial={{ x: -25, opacity: 0 }}
      transition={{
        duration: 0.5
      }}
      animate={{
        x: 0,
        opacity: 1
      }}
    >
      <div className="px-8 py-3 flex justify-center sticky bg-primary top-0  h-[5rem] z-50">
        <Image src="../../images/logo.png" alt="logo" />
      </div>
      <div className="px-8 py-3 flex justify-between sticky bg-white top-[5rem] z-50 lg:hidden ">
        <div className="flex justify-center items-center ">
          <button
            className="w-2 flex items-center"
            onClick={() => setEmpezar(false)}
          >
            <Image src="../../images/flecha-back.png" alt="avatar" />
          </button>

          <Image
            src="../../images/hombre.png"
            alt="avatar"
            width={25}
            height={25}
          />

          <p className="text-secondary  text-sm font-black">
            Epack <span>BOT</span>
          </p>
        </div>

        <p className="text-terciary  text-sm font-black">Respuestas</p>
      </div>
      <div className="flex  flex-col w-full h-full lg:flex-row relative overflow-hidden max-h-[85vh] lg:max-h-[90.5vh]">
        <Respuestas
          setEmpezar={setEmpezar}
          mensaje={mensaje}
          nombre={nombre}
          data={state}
        />
        <div className="flex flex-col flex-1 overflow-y-scroll " id="cuerpo">
          <div className="bg-highlight flex-1 px-8 py-3 flex flex-col gap-5">
            <Mensaje
              bgColor="white"
              w="inline-block"
              color="text-terciary"
              texto=" !Hola, bienvenido a EPACK! Mi nombre es Epack y estoy aquí para
          ayudarte a encontar la mejor opción de nuestros productos para
          usted. ¿Cuál es tu nombre?"
            />
            {mensaje && (
              <>
                <motion.div
                  className="flex justify-end"
                  initial={{ y: 25, opacity: 0 }}
                  transition={{
                    duration: 0.5
                  }}
                  animate={{
                    y: 0,
                    opacity: 1
                  }}
                >
                  <Mensaje
                    w="inline-block"
                    bgColor="yellow"
                    max="15rem"
                    texto={`Mi nombre es ${nombre}`}
                  />
                </motion.div>
                <motion.div
                  className="flex justify-start"
                  initial={{ y: 25, opacity: 0 }}
                  transition={{
                    duration: 0.5,
                    delay: 0.7
                  }}
                  animate={{
                    y: 0,
                    opacity: 1
                  }}
                >
                  <Mensaje
                    bgColor="white"
                    w="inline-block"
                    color="text-terciary"
                    texto={
                      <p className="text-black  text-[15px] ">
                        !Hola, {nombre} ¡empecemos!
                        <br />
                        <span className="font-black">
                          elige la categoría del producto
                        </span>
                      </p>
                    }
                  />
                </motion.div>
              </>
            )}
          </div>
          {!mensaje && !bolsas && (
            <div className="px-8">
              <p className="text-black text-[15px] mt-7 mb-1">
                !Hola, bienvenido a EPACK! Mi nombre es Ebot y estoy aquí para
                ayudarte a encontar la mejor opción de nuestros productos para
                usted.
                <span className="text-black  font-black text-[15px]">
                  ¿Cuál es tu nombre?
                </span>
              </p>
              <div className="border-4 border-primary  text-[15px] flex w-72 my-2 mb-12">
                <input
                  type="text"
                  placeholder="¿Cual es tu nombre?"
                  className="flex-1 placeholder-black focus:outline-none p-2 "
                  onChange={onChange}
                />
                <button
                  type="button"
                  className="bg-primary p-3 "
                  onClick={onClick}
                >
                  <Image
                    src="../../images/flecha-correcta.png"
                    width={25}
                    height={25}
                    alt="flecha-correcta"
                  />
                </button>
              </div>
            </div>
          )}
          {mensaje && seleccionarCaja && (
            <Bolsas onClickBolsas={onClickBolsas} />
          )}

          {bolsas && (
            <motion.div
              className="py-5"
              initial={{ y: 25, opacity: 0 }}
              transition={{
                duration: 0.5
              }}
              animate={{
                y: 0,
                opacity: 1
              }}
            >
              <div className=" px-16">
                <p className="text-black  text-[15px]">
                  {nombre}! elegiste
                  <span className="font-black">{tipoCaja}</span>a continuación
                  puedes elegir la medida que mas se adecua a tus necesidades.
                </p>
              </div>

              <div className="px-1 mt-7 flex justify-center">
                <Table
                  th={[
                    '',
                    <p className="mt-3 text-[10px] bg-primary sm:mb-1 text-white px-1 rounded-sm md:text-[15px]">
                      CÓDIGO
                    </p>,
                    <p className="-rotate-90 sm:rotate-0 sm:mt-3 sm:mb-1 mb-5 text-[10px] bg-primary text-white px-1 rounded-sm md:text-[15px]">
                      ANCHO
                    </p>,
                    <p className="-rotate-90  sm:rotate-0 sm:mt-3 sm:mb-1 mb-5 text-[10px] bg-primary text-white px-1 rounded-sm md:text-[15px]">
                      FUELLE
                    </p>,
                    <p className="-rotate-90 sm:rotate-0 sm:mt-3 sm:mb-1  mb-5 text-[10px] bg-primary text-white px-1 rounded-sm md:text-[15px]">
                      ALTO
                    </p>,
                    <p className="-rotate-90  sm:rotate-0 sm:mt-3 sm:mb-1  mb-6 text-[10px] bg-primary text-white px-1 rounded-sm md:text-[15px]">
                      GRAMOS
                    </p>,
                    <p className="mt-3 text-[10px] bg-primary sm:mb-1 text-white px-1 rounded-sm md:text-[15px]">
                      SELECCIONAR
                    </p>
                  ]}
                  state={state}
                  data={dataTable}
                  stateChat={stateChat}
                  setStateChat={setStateChat}
                />
              </div>
            </motion.div>
          )}
          {checked && (
            <Preguntas
              setState={setState}
              state={state}
              nombre={nombre}
              cantidad={state.cantidad}
              finalizar={finalizar}
              bolsaMedida={bolsaMedida}
              stateChat={stateChat}
              setStateChat={setStateChat}
            />
          )}

          {finalizar.length !== 0 && (
            <Guardar
              finalizar={finalizar}
              cantidad={state.cantidad}
              nombre={nombre}
              bolsaImg={bolsaImg}
              caja={caja}
              bolsaMedida={bolsaMedida}
              state={state}
              stateChat={stateChat}
            />
          )}
        </div>
      </div>
    </motion.div>
  )
}

export default Chat
