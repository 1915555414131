export const dataTableM = [
  {
    codigo: 'N-04',
    ancho: '13',
    fuelle: '8',
    alto: '13',
    gramos: '50'
  },
  {
    codigo: 'N-06',
    ancho: '15',
    fuelle: '9',
    alto: '27',
    gramos: '50'
  },
  {
    codigo: 'N-08',
    ancho: '16',
    fuelle: '10',
    alto: '32',
    gramos: '50'
  },
  {
    codigo: 'N-12',
    ancho: '18',
    fuelle: '10',
    alto: '32',
    gramos: '60'
  },
  {
    codigo: 'N-20',
    ancho: '21',
    fuelle: '13',
    alto: '41',
    gramos: '60'
  },
  {
    codigo: 'N-30',
    ancho: '25',
    fuelle: '16',
    alto: '38',
    gramos: '90'
  },
  {
    codigo: 'N-35',
    ancho: '34',
    fuelle: '18',
    alto: '34',
    gramos: '90'
  },
  {
    codigo: 'N-40',
    ancho: '31',
    fuelle: '17',
    alto: '41',
    gramos: '90'
  }
]
