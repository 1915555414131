import React, { useEffect, useState } from 'react'
import Mensaje from './mensaje'

const Guardar = ({
  nombre,
  bolsaImg,
  caja,
  bolsaMedida,
  cantidad,
  finalizar,
  state,
  stateChat
}) => {
  const [hora, setHora] = useState()
  const hoy = new Date()
  const horas = hoy.getHours() + ':' + hoy.getMinutes()

  useEffect(() => {
    setHora(horas)
  }, [])

  console.log({ state, stateChat })
  console.log(state.color)

  const medidas = `${bolsaMedida.ancho}cm x ${bolsaMedida.fuelle}cm
  x ${bolsaMedida.alto}cm`

  return (
    <div className="px-8 pb-20">
      <Mensaje
        texto={`${nombre}, genial hemos finalizado, te hacemos un resumen de tu pedido.`}
      />
      <div className="flex  gap-4 my-3 justify-center">
        <div className="w-44 md:w-96">
          <img src={bolsaImg} />
        </div>
        <div>
          <p className="text-black font-black text-[10px] md:text-[25px]">
            {caja}
          </p>
          <p className=" text-black font-black text-[10px] md:text-[25px]">
            {bolsaMedida.codigo}({medidas})
          </p>
          <p className=" text-black font-black text-[10px] md:text-[25px]">
            Cantidad: {cantidad} und
          </p>
          <p className="text-black font-black text-[10px] md:text-[25px]">
            {finalizar === 'SI' ? 'Con Factura' : 'Sin Factura'}
          </p>
          <br />
          <p className=" text-black font-black text-[10px] md:text-[25px]">
            Sub total :S/435.00
          </p>
          <p className="text-black font-black text-[10px] md:text-[25px]">
            Impresión :S/120.00
          </p>
          <p className=" text-black font-black text-[10px] md:text-[25px]">
            Delivery :S/30.00
          </p>
          <p className=" text-primary font-black  text-[10px] md:text-[25px]">
            TOTAL :S/585.00
          </p>
          <p className="text-xs text-[8px] text-terciary text-right md:text-[15px]">
            Hora actual: {hora}
          </p>
        </div>
      </div>

      <div className="flex justify-center  gap-1 text-white text-center text-lg  mt-2 mb-2">
        <button className="bg-red-600 rounded-md  flex-1 text-[12px] text-center sm:py-1 md:py-2">
          COMPRAR
        </button>
        <button className="bg-secondary rounded-md  flex-1 text-[12px] text-center sm:py-1 md:py-2">
          IMPRIMIR
        </button>
        <button className=" bg-terciary rounded-md  flex-1 text-[12px] text-center sm:py-1 md:py-2">
          GUARDAR
        </button>
        <button className=" bg-yellow rounded-md  flex-1 text-[12px] text-center sm:py-1  md:py-2">
          ENVIAR POR CORREO
        </button>
      </div>
      <div className="flex justify-center ">
        <a
          href={`https://api.whatsapp.com/send?phone=+51991615223&text=Buen%20dia,%20me%20llamo%20*${nombre}*.%0A%0ACon%20la%20intenci%C3%B3n%20de%20solicitar%20el%20siguiente%20pedido:%0A%0ATipo%20de%20Bolsa:%20*${caja}*%0ABolsa:%20*${
            bolsaMedida.codigo
          }*%0AMedidas:%20*${medidas}*%0A${
            state.color !== '5C5A5A' ? `Color: *${state.color}*%0A` : ''
          }Gramaje:%20*${
            stateChat.bolsaMedida.gramos
          }gr*%0ACantidad:%20*${cantidad}%20unidades*%0ATipo%20de%20Env%C3%ADo:%20*${
            state.delivery === 'SI' ? 'Delivery' : 'Recojo en tienda'
          }*%0ATipo%20de%20Pago:%20*${
            finalizar === 'SI' ? 'Con Factura' : 'Sin Factura'
          }*%0ACon%20Subtotal:%20*S/%2079.00*%0ADando%20un%20Total:%20*S/%2079.00*%0A%0ACon%20el%20tiempo%20de%20entrega:%20*24%20horas*%0AEn%20el%20lugar%20de%20${
            state.direccion === '' ? 'recojo: ' : 'entrega: '
          }*${
            state.direccion.length !== 0
              ? ''
              : 'Av. Precursores 966, San Miguel'
          }${state.direccion}*%0ADistrito:%20*Cercado%20de%20Lima*`}
          className=" bg-green-600 rounded-md cursor-pointer text-[12px] text-center  text-white p-1 px-2 py-1 sm:py-2 md:py-3 md:px-5 "
        >
          ENVIAR AL WHATSAPP
        </a>
      </div>
    </div>
  )
}

export default Guardar
