import React from 'react'
import { motion } from 'framer-motion'
import { StaticImage as Image } from 'gatsby-plugin-image'
const Bolsas = ({ onClickBolsas }) => {
  return (
    <motion.div
      className="flex justify-center"
      initial={{ y: 25, opacity: 0 }}
      transition={{
        duration: 0.5,
        delay: 0.2
      }}
      animate={{
        y: 0,
        opacity: 1
      }}
    >
      <div className="px-8 grid grid-cols-2 md:grid-cols-3  gap-3 py-7 ">
        <button
          className="flex bg-highlight rounded-xl items-center justify-center p-2 gap-2 "
          onClick={() =>
            onClickBolsas(' bolsas kraft sin asa ', 4, 'BOLSA SIN ASA')
          }
        >
          <div className="w-[3.5rem] lg:w-[6rem]">
            <Image src="../images/bolsa4.png" alt="bolsa-4" />
          </div>
          <div>
            <p className="text-xs lg:text-lg text-black font-bold">
              Bolsas Kraft
            </p>
            <p className="text-xs lg:text-lg text-black font-bold">SIN ASA</p>
          </div>
        </button>

        <button
          className="flex bg-highlight rounded-xl items-center justify-center p-2 gap-2"
          onClick={() =>
            onClickBolsas(' bolsas kraft con asa ', 1, 'BOLSA CON ASA')
          }
        >
          <div className="w-[3.5rem] lg:w-[6rem]">
            <Image src="../images/bolsa1.png" alt="bolsa-1" />
          </div>
          <div>
            <p className="text-xs lg:text-lg text-black font-bold">
              Bolsas Kraft
            </p>
            <p className="text-xs lg:text-lg text-black font-bold">CON ASA</p>
          </div>
        </button>
        <button
          className="flex bg-highlight rounded-xl items-center justify-center p-2 gap-2"
          onClick={() =>
            onClickBolsas(' bolsas kraft doy pack ', 3, 'BOLSA DOY PACK')
          }
        >
          <div className="w-[3.5rem] lg:w-[6rem]">
            <Image src="../images/bolsa3.png" alt="bolsa-3" />
          </div>
          <div>
            <p className="text-xs lg:text-lg text-black font-bold">
              Bolsas Kraft
            </p>
            <p className="text-xs lg:text-lg text-black font-bold">DOY PACK</p>
          </div>
        </button>
        <button
          className="flex bg-highlight rounded-xl items-center justify-center p-2 gap-2"
          onClick={() =>
            onClickBolsas(
              ' cajas de cartón microcorrugado ',
              6,
              'CARTON MICROCORRUGADO'
            )
          }
        >
          <div className="w-[3.5rem] lg:w-[6rem]">
            <Image src="../images/bolsa6.png" alt="bolsa-6" />
          </div>
          <div>
            <p className="text-xs lg:text-lg text-black font-bold">
              Cajas de Cartón
            </p>
            <p className="text-xs lg:text-lg text-black font-bold">
              microcorrugado
            </p>
          </div>
        </button>
        <button
          className="flex bg-highlight rounded-xl items-center justify-center p-2 gap-2"
          onClick={() =>
            onClickBolsas(' bolsas lines con asa riñon ', 2, 'CON ASA RIÑON')
          }
        >
          <div className="w-[3.5rem] lg:w-[6rem]">
            <Image src="../images/bolsa2.png" alt="bolsa-2" />
          </div>
          <div>
            <p className="text-xs lg:text-lg text-black font-bold">
              Bolsas Liner
            </p>
            <p className="text-xs lg:text-lg text-black font-bold">
              CON ASA RIÑON
            </p>
          </div>
        </button>
        <button
          className="flex bg-highlight rounded-xl items-center justify-center p-2 gap-2"
          onClick={() =>
            onClickBolsas(' bolsas lines con asa yute ', 5, 'CON ASA YUTE')
          }
        >
          <div className="w-[3.5rem] lg:w-[6rem]">
            <Image src="../images/bolsa5.png" alt="bolsa-5" />
          </div>
          <div>
            <p className="text-xs lg:text-lg text-black font-bold">
              Bolsas Liner
            </p>
            <p className="text-xs lg:text-lg text-black font-bold">
              CON ASA YUTE
            </p>
          </div>
        </button>
      </div>
    </motion.div>
  )
}

export default Bolsas
