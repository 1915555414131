import React from 'react'
import { StaticImage as Image } from 'gatsby-plugin-image'

const Respuestas = ({ setEmpezar, mensaje, nombre, data }) => {
  return (
    <div
      className="px-5 w-1/4 hidden  sticky
  lg:block top-[5rem] z-40"
    >
      <div className="flex items-end gap-3">
        <button
          className="w-4 mt-2 flex items-center"
          onClick={() => setEmpezar(false)}
        >
          <Image src="../../images/flecha-back.png" alt="back" />
        </button>
        <p className="text-secondary  text-sm font-black">
          Epack <span>BOT</span>
        </p>
      </div>

      <div className="flex flex-col justify-center items-center mt-16">
        <Image
          src="../../images/hombre.png"
          alt="avatar"
          width={100}
          height={100}
        />
        <p className="mt-2 font-black">¡Bienvenido, {mensaje && nombre}</p>
        <p className="mt-5 ">TUS RESPUESTAS</p>
        <p className="mt-2 font-black">
          {data.cantidad !== 0 && `Cantidad : ${data.cantidad} und`}
        </p>
        <p className="mt-2 font-black">
          {data.impresion.length !== 0 &&
            `Impresión de Marca : ${data.impresion}`}
        </p>
        {data.impresion === 'SI' && (
          <>
            <p className="mt-2 font-black">
              {data.lados.length !== 0 && `Lados : ${data.lados}`}
            </p>
            <p className="mt-2 font-black">
              {data.color !== '5C5A5A' && `Color : ${data.color}`}
            </p>
            <p className="mt-2 font-black">
              {data.file.length !== 0 && `Logo : SI`}
            </p>
          </>
        )}

        <p className="mt-2 font-black">
          {data.delivery.length !== 0 && `Delivery : ${data.delivery}`}
        </p>
      </div>
    </div>
  )
}

export default Respuestas
