import React, { useState } from 'react'
import Mensaje from './mensaje'
import { motion } from 'framer-motion'
import { StaticImage as Image } from 'gatsby-plugin-image'
import Button from './button'

const Preguntas = ({
  nombre,
  finalizar,
  bolsaMedida,
  cantidad,
  state,
  setState,
  stateChat,
  setStateChat
}) => {
  const cuerpo = document.getElementById('cuerpo')

  const changeImage = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = (e) => {
        e.preventDefault()
        setState({ ...state, file: e.target.result })
      }
      setTimeout(() => {
        cuerpo.scrollTo({
          top: cuerpo.scrollHeight,
          behavior: 'smooth'
        })
      }, 100)
    }
  }

  const onChange = (e) => {
    setState({ ...state, direccion: e.target.value })
  }

  const cantidadSelect = (valor) => {
    setState({ ...state, cantidad: valor })
    setTimeout(() => {
      cuerpo.scrollTo({
        top: cuerpo.scrollHeight,
        behavior: 'smooth'
      })
    }, 100)
  }

  const impresionSelect = (valor) => {
    setState({ ...state, impresion: valor })
    setTimeout(() => {
      cuerpo.scrollTo({
        top: cuerpo.scrollHeight,
        behavior: 'smooth'
      })
    }, 100)
  }

  const ladoSelect = (valor) => {
    setState({ ...state, lados: valor })
    setTimeout(() => {
      cuerpo.scrollTo({
        top: cuerpo.scrollHeight,
        behavior: 'smooth'
      })
    }, 100)
  }
  const colorSelect = (valor) => {
    setState({ ...state, color: valor })
    setTimeout(() => {
      cuerpo.scrollTo({
        top: cuerpo.scrollHeight,
        behavior: 'smooth'
      })
    }, 100)
  }
  const adjuntarSelect = (valor) => {
    setState({ ...state, adjuntar: valor })
    setTimeout(() => {
      cuerpo.scrollTo({
        top: cuerpo.scrollHeight,
        behavior: 'smooth'
      })
    }, 100)
  }

  const deliverySelect = (valor) => {
    setState({ ...state, delivery: valor })
    setTimeout(() => {
      cuerpo.scrollTo({
        top: cuerpo.scrollHeight,
        behavior: 'smooth'
      })
    }, 100)
  }
  const guardarSelect = (valor) => {
    setState({ ...state, guardar: valor })
    setTimeout(() => {
      cuerpo.scrollTo({
        top: cuerpo.scrollHeight,
        behavior: 'smooth'
      })
    }, 100)
  }

  return (
    <motion.div
      className={`${!finalizar && 'pb-10'} px-8`}
      initial={{ y: 25, opacity: 0 }}
      transition={{
        duration: 0.5
      }}
      animate={{
        y: 0,
        opacity: 1
      }}
    >
      <Mensaje
        texto={
          <>
            <p>!Genial, {nombre}</p>
            <p>
              haz elegido la bolsa {bolsaMedida.codigo} que mide{' '}
              {bolsaMedida.ancho}cmx
              {bolsaMedida.fuelle}cmx
              {bolsaMedida.alto}cm que es de {bolsaMedida.gramos} gramos
            </p>
          </>
        }
      />
      <Mensaje
        texto={`${nombre}, sigamos completando los datos del proceso de compra.`}
      />
      <Mensaje texto={`¿${nombre}, que cantidad de bolsas estas buscando?`} />
      <div className="flex justify-between gap-3 mt-2 mb-2 text-lg text-center text-white ">
        <Button
          className={state.cantidad === 100 ? 'bg-secondary' : 'bg-primary'}
          value="100 und"
          onClick={() => cantidadSelect(100)}
          disabled={state.guardar}
        />
        <Button
          className={state.cantidad === 500 ? 'bg-secondary' : 'bg-primary'}
          value="500 und"
          onClick={() => cantidadSelect(500)}
          disabled={state.guardar}
        />
        <Button
          className={state.cantidad === 1000 ? 'bg-secondary' : 'bg-primary'}
          value="1,000 und"
          onClick={() => cantidadSelect(1000)}
          disabled={state.guardar}
        />
      </div>
      {cantidad !== 0 && (
        <>
          <Mensaje
            texto={`¿${nombre}, lo deseas con la impresión de tu marca?`}
          />
          <div className="flex justify-between gap-1 mt-2 mb-2 text-lg text-center text-white">
            <Button
              className={
                state.impresion === 'SI' ? 'bg-secondary' : 'bg-primary'
              }
              value="SI"
              onClick={() => impresionSelect('SI')}
              disabled={state.guardar}
            />
            <Button
              className={
                state.impresion === 'NO' ? 'bg-secondary' : 'bg-primary'
              }
              value="NO"
              onClick={() => impresionSelect('NO')}
              disabled={state.guardar}
            />
          </div>
        </>
      )}
      {state.impresion === 'SI' && (
        <>
          <Mensaje texto={`¿${nombre}, la impresión es en 1 lado o 2 lados?`} />
          <div className="flex justify-between gap-3 mt-2 mb-2 text-lg text-center text-white">
            <Button
              className={state.lados === '1' ? 'bg-secondary' : 'bg-primary'}
              value="1 lado"
              onClick={() => ladoSelect('1')}
              disabled={state.guardar}
            />
            <Button
              className={state.lados === '2' ? 'bg-secondary' : 'bg-primary'}
              value="2 lados"
              onClick={() => ladoSelect('2')}
              disabled={state.guardar}
            />
          </div>
        </>
      )}
      {state.lados.length !== 0 && (
        <>
          <Mensaje
            texto={`¿${nombre}, la impresión que color desea que sea?`}
          />
          <div className="flex justify-center w-full gap-1 mt-2 mb-2 text-lg text-center text-white">
            <div className="flex justify-center gap-1 mt-2 mb-2 text-lg text-center text-white lg:w-1/2">
              <button
                className={`${
                  state.guardar && 'opacity-60'
                } bg-black  rounded-md border-2 border-black p-4 md:p-5`}
                onClick={() => colorSelect('000000')}
                disabled={state.guardar}
              ></button>
              <button
                className={`${
                  state.guardar && 'opacity-60'
                } bg-primary  rounded-md border-2 border-black p-4 md:p-5`}
                onClick={() => colorSelect('564596')}
                disabled={state.guardar}
              ></button>
              <button
                className={`${
                  state.guardar && 'opacity-60'
                } bg-secondary  rounded-md border-2 border-black p-4 md:p-5`}
                onClick={() => colorSelect('#00E8FF')}
                disabled={state.guardar}
              ></button>
              <button
                className={`${
                  state.guardar && 'opacity-60'
                } bg-red-600  rounded-md border-2 border-black p-4 md:p-5`}
                onClick={() => colorSelect('DC2626')}
                disabled={state.guardar}
              ></button>
              <button
                className={`${
                  state.guardar && 'opacity-60'
                } bg-white  rounded-md border-2 border-black p-4 md:p-5`}
                onClick={() => colorSelect('FFFFFF')}
                disabled={state.guardar}
              ></button>
              <button
                className={`${
                  state.guardar && 'opacity-60'
                } bg-white flex-1 rounded-md border-2 border-black text-black font-black`}
              >
                #{state.color}
              </button>
            </div>
          </div>
        </>
      )}
      {state.color !== '5C5A5A' && (
        <>
          <Mensaje texto={`¿${nombre}, vamos bien,ya casi hemos acabdo.`} />
          <Mensaje texto={`¿${nombre}, deseas adjuntar tu logo.`} />
          <div className="flex justify-between gap-3 mt-2 mb-2 text-lg text-center text-white">
            <Button
              className={
                state.adjuntar === 'SI' ? 'bg-secondary' : 'bg-primary'
              }
              value="SI"
              onClick={() => adjuntarSelect('SI')}
              disabled={state.guardar}
            />
            <Button
              className={
                state.adjuntar === 'NO' ? 'bg-secondary' : 'bg-primary'
              }
              value="NO"
              onClick={() => adjuntarSelect('NO')}
              disabled={state.guardar}
            />
          </div>
          {state.adjuntar === 'SI' && (
            <div
              className={`${
                state.guardar && 'opacity-60'
              } flex gap-2 w-3/5 relative py-2`}
            >
              <div className="flex-1 p-1 text-center border-2 border-primary md:py-3">
                <p className="text-xs font-black text-primary">
                  (PDF,AI,CDR,PNG,JPG)
                </p>
              </div>
              <div className="w-[30px] md:w-[45px]">
                <Image src="../../images/f.png" alt="flecha" />
              </div>
              <input
                type="file"
                className="absolute top-0 w-full py-2 outline-none opacity-0"
                onChange={(e) => changeImage(e)}
                disabled={state.guardar}
              />
            </div>
          )}
        </>
      )}
      {(state.file.length !== 0 ||
        state.impresion === 'NO' ||
        state.adjuntar === 'NO') && (
        <>
          <Mensaje texto={`¿${nombre} deseas con delivery?`} />
          <div className="flex justify-between gap-3 mt-2 mb-2 text-lg text-center text-white">
            <Button
              disabled={state.guardar}
              className={`${
                state.delivery === 'SI' ? 'bg-secondary' : 'bg-primary'
              } text-[12px] md:text-base`}
              value="SI, deseo delivery"
              onClick={() => deliverySelect('SI')}
            />
            <Button
              disabled={state.guardar}
              className={`${
                state.delivery === 'NO' ? 'bg-secondary' : 'bg-primary'
              } text-[12px] md:text-base`}
              value="NO, lo recogerá en tienda"
              onClick={() => deliverySelect('NO')}
            />
          </div>
        </>
      )}
      {state.delivery === 'SI' && (
        <>
          <input
            disabled={state.guardar}
            type="text"
            placeholder="Dirección"
            value={state.direccion}
            onChange={onChange}
            className={`${
              state.guardar && 'opacity-60'
            } text-[15px] placeholder-black w-full focus:outline-none border-2 border-primary rounded-md px-5 py-1 md:py-2`}
          />

          {/* aca va el google maps */}

          <div className="w-full h-40 my-5 rounded-md bg-primary md:h-60"></div>
          <Button
            className={`${
              state.guardar ? 'bg-secondary' : 'bg-primary'
            } w-full py-2 md:py-3`}
            value="Guardar"
            onClick={() => guardarSelect(true)}
            disabled={state.guardar}
          />
        </>
      )}
      {state.delivery === 'NO' && (
        <Button
          className={`${
            state.guardar ? 'bg-secondary' : 'bg-primary'
          } w-full py-2 md:py-3`}
          value="Guardar"
          onClick={() => guardarSelect(true)}
          disabled={state.guardar}
        />
      )}
      {state.guardar && (
        <>
          <Mensaje texto={`${nombre}, para finalizar deseas con factura?`} />
          <div className="flex justify-between gap-1 mt-2 mb-2 text-lg text-center text-white">
            <Button
              className={
                stateChat.finalizar === 'SI' ? 'bg-secondary' : 'bg-primary'
              }
              value="SI"
              onClick={() => {
                setStateChat({ ...stateChat, finalizar: 'SI' })
                setTimeout(() => {
                  cuerpo.scrollTo({
                    top: cuerpo.scrollHeight,
                    behavior: 'smooth'
                  })
                }, 100)
              }}
            />
            <Button
              className={
                stateChat.finalizar === 'NO' ? 'bg-secondary' : 'bg-primary'
              }
              value="NO"
              onClick={() => {
                setStateChat({ ...stateChat, finalizar: 'NO' })
                setTimeout(() => {
                  cuerpo.scrollTo({
                    top: cuerpo.scrollHeight,
                    behavior: 'smooth'
                  })
                }, 100)
              }}
            />
          </div>
        </>
      )}
    </motion.div>
  )
}

export default Preguntas
