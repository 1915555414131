import React, { useState } from 'react'

const Table = ({ th, data, stateChat, setStateChat, state }) => {
  const cuerpo = document.getElementById('cuerpo')
  const [check, setCheck] = useState(null)

  return (
    <table
      className={`${state.guardar && 'opacity-60'} w-full md:w-3/4 lg:w-4/5`}
    >
      <thead>
        <tr>
          {th.map((titulo) => (
            <th>{titulo}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((data, index) => (
          <tr className=" border-b-4 border-white">
            <td className="bg-highlight">
              <div className="w-[2.5rem]">
                <img src={data?.imagen} alt={`img-${index}`} />
              </div>
            </td>

            <td className="bg-highlight">
              <p className="text-primary text-xl text-center">{data?.codigo}</p>
            </td>

            <td className="bg-highlight">
              <p className="text-primary text-base text-center">
                {data?.ancho}
                <span className="text-xs">cm</span>
              </p>
            </td>
            <td className="bg-highlight">
              <p className="text-primary text-base text-center">
                {data?.fuelle}
                <span className="text-xs">cm</span>
              </p>
            </td>
            <td className="bg-highlight">
              <p className="text-primary text-base text-center">
                {data?.alto}
                <span className="text-xs">cm</span>
              </p>
            </td>
            <td className="bg-highlight">
              <p className="text-secondary font-black text-base text-center">
                {data?.gramos}
                <span className="text-xs">gr</span>
              </p>
            </td>
            <td className="bg-highlight">
              <div className="flex justify-center  ">
                <input
                  type="radio"
                  className=" appearance-none h-4 w-4 border-2 border-secondary rounded-sm bg-white checked:bg-primary focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                  id="check"
                  name="bolsas"
                  value={index}
                  disabled={state.guardar}
                  checked={check === index}
                  onChange={(e) => {
                    setCheck(Number(e.target.value))
                    setStateChat({
                      ...stateChat,
                      checked: true,
                      bolsaMedida: data
                    })
                    setTimeout(() => {
                      cuerpo.scrollTo({
                        top: cuerpo.scrollHeight,
                        behavior: 'smooth'
                      })
                    }, 100)
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
